import axios from 'axios';
import configs from '../configs';

class ApiService {
  constructor() {
    this.baseURL = configs.api;
  }

  save(data) {
    axios.defaults.withCredentials = true
    return axios.post(this.baseURL, data);
  }

}

export default new ApiService();
