import { observer } from 'mobx-react';
import React, { useState } from 'react';
import HomeStore from '../../../store/HomeStore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import Box from '@material-ui/core/Box';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, makeStyles, Paper, Radio, RadioGroup, withStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    margin: 0,
    width: '100%',
  },
  paperInactive: {
    border: 'solid rgba(158, 158, 158, 1)',
    boxShadow:
      '0px 1px 3px 0px rgba(158 158 158 / 20%), 0px 1px 1px 0px rgba(158 158 158 / 14%), 0px 2px 1px -1px rgba(158 158 158 / 12%)',
  },
  paperActive: {
    border: 'solid rgba(240, 127, 35, 1)',
    boxShadow:
      '0px 1px 3px 0px rgba(240 127 35 / 20%), 0px 1px 1px 0px rgba(240 127 35 / 14%), 0px 2px 1px -1px rgba(240 127 35 / 12%)',
  },
  paperGrid: {
    margin: 5,
  },
}));

const OrangeRadio = withStyles({
  root: {
    color: 'rgba(158, 158, 158, 1)',
    '&$checked': {
      color: 'rgba(240, 127, 35, 1)',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const StepTwoPronto = observer(function StepTwoPronto() {
  const classes = useStyles();
  const [option, setOption] = useState('papi');

  const fields = {
    option: {
      label: 'Pronto Option',
      name: 'option',
      validate: null,
      required: true,
    },
    host: {
      label: 'Pronto Host',
      name: 'host',
      validate: null,
      required: true,
    },
    username: {
      label: 'Pronto Username',
      name: 'username',
      validate: null,
      required: true,
    },
    pass: {
      label: 'Pronto Password',
      name: 'pass',
      validate: null,
      required: true,
    },
    company: {
      label: 'Tenant',
      name: 'company',
      validate: null,
      required: true,
    },
  };

  fields.option.validate = HomeStore.hasErrorInField(fields.option.name);
  fields.host.validate = HomeStore.hasErrorInField(fields.host.name);
  fields.username.validate = HomeStore.hasErrorInField(fields.username.name);
  fields.pass.validate = HomeStore.hasErrorInField(fields.pass.name);
  fields.company.validate = HomeStore.hasErrorInField(fields.company.name);

  return (
    <div>
      <Box component="div" m={4}>
        <RadioGroup
          aria-labelledby="pronto-option"
          defaultValue="papi"
          name="radio-buttons-group"
          className={classes.root}
          onChange={e => {
            HomeStore.option = e.currentTarget.value;
            setOption(e.currentTarget.value);
          }}
        >
          <Grid container>
            <Grid item sm>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={5}>
              <Paper className={`${classes.paper} ${option === 'papi' ? classes.paperActive : classes.paperInactive}`}>
                <FormControlLabel value="papi" control={<OrangeRadio />} label="Private API" />
                <br />
                Integration using EzyCollect Agent
              </Paper>
            </Grid>
            <Grid item sm>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={5}>
              <Paper
                className={`${classes.paper} ${option === 'connect' ? classes.paperActive : classes.paperInactive}`}
              >
                <FormControlLabel value="connect" control={<OrangeRadio />} label="Pronto Connect" />
                <br />
                Integration using{' '}
                <a href="https://kbase.pronto.com.au/public/APIreference/help/index.htm#welcome.htm%3FTocPath%3D_____1">
                  Pronto Connect API
                </a>
              </Paper>
            </Grid>
            <Grid item sm>
              &nbsp;
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
      {option === 'papi' ? (
        <Box component="div" m={4}>
          A key will be generated on the next step
        </Box>
      ) : (
        <Box component="div" m={4}>
          <Grid item xs={12}>
            <TextField
              required
              label={fields.host.label}
              margin="dense"
              variant="outlined"
              autoComplete="new-string"
              name={fields.host.name}
              id="host"
              value={HomeStore.step2Model.host}
              helperText={fields.host.validate.message}
              error={fields.host.validate.hasError}
              className="ez-fw"
              onChange={e => HomeStore.updateValueStep2(e.target.name, e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessTwoToneIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ padding: '0px 15px 15px 15px' }}>
              <Typography variant="caption" display="inline" gutterBottom color="secondary">
                Your pronto host like https://your-subdomain.prontohosted.com.au
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label={fields.username.label}
              margin="dense"
              variant="outlined"
              autoComplete="new-string"
              name={fields.username.name}
              id="username"
              value={HomeStore.step2Model.username}
              helperText=""
              error={fields.username.validate.hasError}
              className="ez-fw"
              onChange={e => HomeStore.updateValueStep2(e.target.name, e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineTwoToneIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label={fields.pass.label}
              margin="dense"
              variant="outlined"
              autoComplete="new-string"
              name={fields.pass.name}
              id="pass"
              value={HomeStore.step2Model.pass}
              helperText=""
              error={fields.pass.validate.hasError}
              className="ez-fw"
              onChange={e => HomeStore.updateValueStep2(e.target.name, e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockTwoToneIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label={fields.company.label}
              margin="dense"
              variant="outlined"
              autoComplete="new-string"
              name={fields.company.name}
              id="company"
              value={HomeStore.step2Model.company}
              helperText=""
              error={fields.company.validate.hasError}
              className="ez-fw"
              onChange={e => HomeStore.updateValueStep2(e.target.name, e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StarTwoToneIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Box>
      )}
      <Box component="div" m={4}>
        <Grid container>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit" size="large" onClick={() => HomeStore.goToBack()}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} className="ez-text-right">
            <Button variant="contained" color="primary" type="submit" size="large" onClick={() => HomeStore.goToNext()}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});

export default StepTwoPronto;
