import React, { Component } from 'react';
import { observer } from 'mobx-react';
import HomeStore from '../../../store/HomeStore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import Recaptcha from 'react-google-invisible-recaptcha';
import Typography from '@material-ui/core/Typography';
import configs from '../../../configs';
import { FormControlLabel } from '@material-ui/core';

@observer
class StepOneXeroApp extends Component {
  constructor(props) {
    super(props);
    this.handlerSubmit = this.handlerSubmit.bind(this);

    this.fields = {
      companyName: {
        label: 'Company Name',
        name: 'companyName',
        validate: null,
        required: true,
      },
      contactName: {
        label: 'Your Name',
        name: 'contactName',
        validate: null,
        required: true,
      },
      contactEmail: {
        label: 'E-mail',
        name: 'contactEmail',
        validate: null,
        required: true,
      },
      password: {
        label: 'Password',
        name: 'password',
        validate: null,
        required: true,
      },
      contactPhone: {
        label: 'Phone',
        name: 'contactPhone',
        validate: null,
        required: false,
      },
    };

    document.onkeyup = e => {
      HomeStore.checkCapsLock(e);
    };

    document.onkeydown = e => {
      HomeStore.checkCapsLock(e);
    };
  }

  handlerSubmit(e) {
    HomeStore.updateValue("isXeroApp", true);
    if(HomeStore.step1Model.termsAndConditions) {
      this.recaptcha.execute();
      e.preventDefault();
    }
  }

  render() {
    this.fields.companyName.validate = HomeStore.hasErrorInField(this.fields.companyName.name);
    this.fields.contactName.validate = HomeStore.hasErrorInField(this.fields.contactName.name);
    this.fields.contactEmail.validate = HomeStore.hasErrorInField(this.fields.contactEmail.name);
    this.fields.password.validate = HomeStore.hasErrorInField(this.fields.password.name);

    return (
      <form onSubmit={this.handlerSubmit} autoComplete="off">
        <input id="_suburb" type="text" style={{ display: 'none' }} disabled />
        <Recaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey={configs.captcha_key}
          onResolved={() => HomeStore.goToNext()}
        />
        <Box component="div" m={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label={this.fields.companyName.label}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name={this.fields.companyName.name}
                id="companyName"
                value={HomeStore.step1Model.companyName}
                error={this.fields.companyName.validate.hasError}
                helperText={this.fields.companyName.validate.message}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={this.fields.contactName.label}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name={this.fields.contactName.name}
                id="contactName"
                value={HomeStore.step1Model.contactName}
                error={this.fields.contactName.validate.hasError}
                helperText={this.fields.contactName.validate.message}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name="contactPhone"
                id="contactPhone"
                value={HomeStore.step1Model.contactPhone}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Divider className="ez-vm-24" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label={this.fields.contactEmail.label}
                name={this.fields.contactEmail.name}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                id="contactEmail"
                type="email"
                error={this.fields.contactEmail.validate.hasError}
                helperText={this.fields.contactEmail.validate.message}
                value={HomeStore.step1Model.contactEmail}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={`${this.fields.password.label} ${HomeStore.isCapsLockEnabled ? ' [Caps Lock Enabled]' : ''}`}
                name={this.fields.password.name}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                id="password"
                error={this.fields.password.validate.hasError}
                helperText={this.fields.password.validate.message}
                value={HomeStore.step1Model.password}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                type={HomeStore.showPass ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => HomeStore.togglePass()}
                      >
                        {HomeStore.showPass ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ padding: '0 15px' }}>
                <Typography variant="caption" display="inline" gutterBottom color="secondary">
                  Between 6 to 22 characters, with at least 1 lowercase, 1 uppercase and 1 number
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginLeft: '5px' }}
                    id="terms-conditions"
                    checked={HomeStore.step1Model.termsAndConditions}
                    onChange={e => HomeStore.updateValue('termsAndConditions', e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={(<label htmlFor="terms-conditions">
                  I have read and accept <a
                    style={{ color: 'rgba(240, 127, 35, 1)' }}
                    href="https://ezycollect.io/free-trial-terms-conditions/" 
                    target="_blank" 
                    rel="noopener noreferrer">Terms and Conditions
                  </a> for the free trial
                </label>)}
              />
            </Grid>
            <Grid item xs={12} className="ez-text-right">
              <Button
                disabled={!HomeStore.step1Model.termsAndConditions}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

export default StepOneXeroApp;
