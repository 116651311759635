import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const ezyTheme = createMuiTheme({
  palette: {
    common: {
      black: '#0D1E28',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#E5E8EA',
    },
    secondary: {
      light: 'rgba(87, 128, 140, 1)',
      main: 'rgba(51, 82, 102, 1)',
      dark: 'rgba(22, 59, 81, 1)',
      contrastText: '#fff',
    },
    primary: {
      light: 'rgba(232, 167, 33, 1)',
      main: 'rgba(240, 127, 35, 1)',
      dark: 'rgba(236, 76, 40, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#0D1E28',
      secondary: '#335266',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export { ezyTheme, ThemeProvider, createMuiTheme };
