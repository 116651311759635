import React, { Component } from 'react';
import { observer } from 'mobx-react';
import HomeStore from '../../../store/HomeStore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import ClipboardJS from 'clipboard';
import RestImage from '../../../images/rest.png';
import configs from '../../../configs';
import Button from '@material-ui/core/Button';

@observer
class StepTwo extends Component {
  constructor(props) {
    super(props);

    this.copyButton = React.createRef();
  }
  componentDidMount() {
    if ((HomeStore.step1Model.cloudIntegration && HomeStore.step1Model.enableCloudIntegration) || HomeStore.step1Model.isXeroApp ) {
      return;
    }

    new ClipboardJS(this.copyButton.current, {
      text: trigger => {
        HomeStore.markCopied();
        return trigger.getAttribute('data-key');
      },
    });
  }
  render() {
    return (
      <div
        style={{
          padding: '215px 24px 24px 24px',
          textAlign: 'center',
          background: `url(${RestImage}) center top no-repeat`,
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '500', color: '#4CC3B6' }}>{HomeStore.messageSuccess}</p>
        {(HomeStore.step1Model.cloudIntegration && HomeStore.step1Model.enableCloudIntegration) || HomeStore.step1Model.isXeroApp ? (
          <></>
        ) : (
          <>
            <p>
              <Typography variant="button" display="inline" gutterBottom>
                <a target="_blank" rel="noopener noreferrer" href={HomeStore.link} style={{ color: '#F07F23' }}>
                  Click here to download the ezyCollect installer for {HomeStore.step1Model.label}
                </a>
              </Typography>
            </p>
            <p>Use the access key below to configure your installation.</p>

            <Grid item xs={12}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                defaultValue={HomeStore.key}
                className="ez-key"
                type="text"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small" data-key={HomeStore.key} title="Copy" ref={this.copyButton}>
                        <Tooltip title={HomeStore.isCopied ? 'Copied' : 'Copy'} aria-label="copy">
                          <FileCopyTwoToneIcon color={HomeStore.isCopied ? 'primary' : 'action'} />
                        </Tooltip>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        { HomeStore.step1Model.isXeroApp ? (
          <Grid item xs={12} style={{ padding: '20px', color: '#335266' }}>
            <Typography variant="body2" gutterBottom>
              Congratulations
            </Typography>
            <Typography variant="body2" gutterBottom>
              Welcome to ezyCollect and to a world of better cash flow!
            </Typography>
            <Typography variant="body2" gutterBottom>
              ezyCollect has been successfully configured to syncronize with your Xero instance.
            </Typography>
            <Typography variant="body2" gutterBottom>
              To login to your instance, go to the{' '}
              <a target="_blank" rel="noopener noreferrer" href={configs.login_link} style={{ color: '#4CC3B6' }}>
                {' '}
                ezyCollect login page
              </a>
              .
            </Typography>

            <h2>Schedule a call with our team at a time that works for you!</h2>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                onClick={() => window.open("https://www.ezycollect.com.au/book-a-time/?utm_medium=Xero_app_store&utm_source=App_store&utm_campaign=Xero-CTA", "_blank")}
              >
                Book a Demo
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ padding: '20px', color: '#335266' }}>
            <Typography variant="body2" gutterBottom>
              Once you are ready to start, follow the steps on our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.ezycollect.com.au/en/articles/3461343-attache-bi-ezycollect-installer-set-up"
                style={{ color: '#4CC3B6' }}
              >
                setup guide
              </a>
              , it only takes a couple of minutes.
            </Typography>

            <Typography variant="body2" gutterBottom>
              To login to your instance, go to the{' '}
              <a target="_blank" rel="noopener noreferrer" href={configs.login_link} style={{ color: '#4CC3B6' }}>
                {' '}
                ezyCollect login page
              </a>
              .
            </Typography>
          </Grid>
        )}
      </div>
    );
  }
}

export default StepTwo;
