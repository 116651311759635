import React from 'react';
import { observer } from 'mobx-react';
import Notifier from './Notifier';

@observer
class Template extends React.Component {
  render() {
    return (
      <div className="root">
        <Notifier />
        {this.props.children}
      </div>
    );
  }
}

export default Template;
