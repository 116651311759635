import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

import EzyLogo from '../../images/ezy-logo.png';
import BgImage from '../../images/bg.jpg';
import Grid from '@material-ui/core/Grid';

const ErrorPage = props => {
  const [errorMsg, setErrorMsg] = useState({
    title: 'Something goes wrong',
    msg: 'Please try again or if this issue persist contact helpdesk@ezycollect.com.au',
  });

  const errorHandler = () => {
    const { provider } = props.match.params;

    if (provider === 'xero') {
      setErrorMsg({
        title: 'Authentication failed',
        msg: 'Please try again or if this issue persist contact helpdesk@ezycollect.com.au',
      });
    }
  };

  useEffect(() => {
    errorHandler();
  });

  return (
    <div>
      <div className="main">
        <div className="side" style={{ background: `url(${BgImage}) 64% center no-repeat` }}>
          <div className="ezy-logo">
            <img src={EzyLogo} alt="ezyCollect" />
          </div>
        </div>
        <div className="content">
          <Box component="div" m={4} color="text.secondary">
            <Typography variant="h6" component="h6" className="ez-text-center">
              {errorMsg.title}
            </Typography>
          </Box>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ErrorIcon style={{ color: 'rgb(250 168 54)', fontSize: '10rem' }}></ErrorIcon>
            </div>
            <Grid item xs={12} style={{ textAlign: 'center', padding: '20px', color: '#335266' }}>
              <Typography variant="body2" gutterBottom>
                {errorMsg.msg}
              </Typography>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorPage;
