import CryptoJS from 'crypto-js';
import { action, observable, configure } from 'mobx';

configure({ enforceActions: 'observed' });

class StalkerStore {
  @observable
  params = {};

  constructor() {
    const env = process.env.REACT_APP_ENV || 'development';
    const isProd = env === 'production';

    let segmentKey = 'w0FlDbPgFRgKgUWP4e4MdUHqmM3HiAyp'; // dev
    // let intercomSecurekey = 'pMQJIQHgY6ZPEKaV_CRPDBsKA7VSV4vSj_Ea-KUs'; // dev

    if (isProd) {
      segmentKey = 'GZP37XlSdl6whC8tCLZB2eyLrsWz01lW'; // prod
      // intercomSecurekey = 'qnhv8cwNDxkqxsC_ri7sGhmQXuUVEVaNyXDLVnTw'; // prod
    }

    this.loadSegment(segmentKey);
  }

  @action
  loadSegment(segmentKey) {
    (function() {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function(t) {
            return function() {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function(t) {
            var e = document.createElement('script');
            e.type = 'text/javascript';
            e.async = !0;
            e.src =
              ('https:' === document.location.protocol ? 'https://' : 'http://') +
              'cdn.segment.com/analytics.js/v1/' +
              t +
              '/analytics.min.js';
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(e, n);
          };
          analytics.SNIPPET_VERSION = '4.0.0';
          analytics.load(segmentKey, {
            integrations: {
              All: false,
              Hotjar: true,
              Intercom: true,
              Mixpanel: true,
              'Google Analytic': true,
            },
          });
        }
    })();
  }

  @action
  setParams(params) {
    this.params = new URLSearchParams(params);
  }

  @action
  init(search) {
    // Create an object to check for AnonymousIds parsed through the URL query string
    this.setParams(search);

    // If an non-default anonId param has been parsed
    if (this.params.has('email')) {
      const email = this.params.get('email');
      this.emailIdentify(email);
    }

    // Tracking code for page views across Registration
    this.page('Visit Registration');

    // Tracking to execute only if on the registration form page
    this.track('Registration - Started', {
      'Form Name': 'Start Your Free Trial',
    });
  }

  @action
  emailIdentify(email, properties) {
    window.analytics.identify(CryptoJS.MD5(email).toString(), {
      email: email,
      test: 'analytics_project',
      ...properties,
    });
  }

  @action
  track(event, properties) {
    window.analytics.track(
      event,
      {
        'Form Name': document.title,
        URL: document.URL,
        test: 'analytics_project',
        ...properties,
      },
      {
        integrations: {
          All: false,
          Hotjar: true,
          Intercom: true,
          Mixpanel: true,
          'Google Analytics': true,
        },
      }
    );
  }

  @action
  page(event, properties) {
    window.analytics.page(event, {
      test: 'analytics_project',
      ...properties,
    });
  }
}

export default new StalkerStore();
