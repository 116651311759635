import { action, observable, configure } from 'mobx';
configure({ enforceActions: 'observed' });

class TemplateStore {
  @observable
  notifications = [];

  @action
  enqueueSnackbar(note) {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...note,
    });
  }

  @action
  removeSnackbar(note) {
    this.notifications.remove(note);
  }
}

export default new TemplateStore();
