const env = process.env.REACT_APP_ENV || 'development';

const configs = {
  development: {
    api: 'http://localhost:8080/api/registration',
    captcha_key: '6Lfc0bwUAAAAABlKe5FaYK3Re1q9QmyajILc-crU',
    login_link: 'http://app.ezycollectuat.io/Ezycollect',
  },
  uat: {
    api: 'https://registration-app.ezycollectuat.io/api/registration',
    captcha_key: '6Lfc0bwUAAAAABlKe5FaYK3Re1q9QmyajILc-crU',
    login_link: 'http://app.ezycollectuat.io/Ezycollect',
  },
  production: {
    api: 'https://registration-app.ezycollect.io/api/registration',
    captcha_key: '6Lfc0bwUAAAAABlKe5FaYK3Re1q9QmyajILc-crU',
    login_link: 'https://app.ezycollect.io/Ezycollect/',
  },
}[env];

export default configs;
