import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Route, Router, Switch } from 'react-router-dom';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import Template from './pages/Template';
import HomePage from './pages/Home/HomePage';
import ErrorPage from './pages/Error/ErrorPage';
import { ezyTheme, ThemeProvider } from './ui-theme';
import { SnackbarProvider } from 'notistack';

import StalkerStore from './store/StalkerStore';

import './styles/mains.scss';

const hashHistory = createBrowserHistory();
const history = syncHistoryWithStore(hashHistory, new RouterStore());

const store = {
  StalkerStore,
};

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={ezyTheme}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={3000}
        style={{
          top: '30px',
        }}
      >
        <Router history={history}>
          <Template>
            <Switch>
              <Route path="/" exact render={props => <HomePage {...props} />} />
              <Route path="/:provider" exact render={props => <HomePage {...props} />} />
              <Route path="/error/:provider" exact render={props => <ErrorPage {...props} />} />
            </Switch>
          </Template>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
