import React, { Component } from 'react';
import { observer } from 'mobx-react';
import HomeStore from '../../store/HomeStore';
import StalkerStore from '../../store/StalkerStore';

import Box from '@material-ui/core/Box';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import EzyLogo from '../../images/ezy-logo.png';
import PlusImage from '../../images/plus.png';
import NoCreditCard from '../../images/no-credit-card.png';
import BgImage from '../../images/bg.jpg';

import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepTwoPronto from './components/StepTwoPronto';
import StepOneXeroApp from './components/StepOneXeroApp';
import SuccessPage from './components/SuccessPage';

@observer
class HomePage extends Component {
  constructor(props) {
    super(props);

    StalkerStore.init(props.location.search);
  }

  componentDidMount() {
    HomeStore.init(this.props.match, this.props.location);
  }

  componentWillUnmount() {
    HomeStore.reset();
  }

  render() {
    return (
      <div>
        {HomeStore.isLoading && (
          <div style={{ position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
            <LinearProgress />
          </div>
        )}

        <div className="main">
          <div className="side" style={{ background: `url(${BgImage}) 64% center no-repeat` }}>
            <div className="ezy-logo">
              <img src={EzyLogo} alt="ezyCollect" />
            </div>
            {HomeStore.step1Model.logo && (
              <React.Fragment>
                <div className="plus">
                  <img src={PlusImage} alt="plus" />
                </div>
                <div className="provider-logo">
                  <img src={HomeStore.step1Model.logo} alt={HomeStore.step1Model.label} />
                </div>
              </React.Fragment>
            )}

            <div className="ez-registration-label">
              Registration wizard for {HomeStore.step1Model.label}. It takes just 60 seconds.
            </div>
            {
              (this.props.match.params.provider !== 'xeroapp') &&
              <div className="ez-registration-label-card">
                <img src={NoCreditCard} alt="No credit card required" />
                <p className="ez-registration-no-card">No credit card required!</p>
              </div>
            }
          </div>
          <div className="content">
            <Box component="div" m={4} color="text.secondary">
              <Typography variant="h6" component="h6" className="ez-text-center">
                Registration Wizard
              </Typography>
            </Box>
            <Stepper activeStep={HomeStore.currentStep} alternativeLabel>
              {HomeStore.step1Model.steps.map(step => {
                return (
                  <Step key={step.id}>
                    <StepLabel completed={HomeStore.currentStep === HomeStore.step1Model.steps.length - 1}>
                      {step.title}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {HomeStore.isLoading ? (
              <div style={{ padding: '24px', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <React.Fragment>
                {HomeStore.currentStep === 0 && this.props.match.params.provider !== 'xeroapp' && <StepOne />}
                {HomeStore.currentStep === 1 && this.props.match.params.provider !== 'pronto' && <StepTwo />}
                {HomeStore.currentStep === 0 && this.props.match.params.provider === 'xeroapp' && <StepOneXeroApp />}
                {HomeStore.currentStep === 1 && this.props.match.params.provider === 'pronto' && <StepTwoPronto />}
                {HomeStore.currentStep === 2 &&
                  this.props.match.params.provider === 'pronto' &&
                  HomeStore.option === 'papi' && <StepTwo />}
                {HomeStore.currentStep === 2 &&
                  this.props.match.params.provider === 'pronto' &&
                  HomeStore.option === 'connect' && <SuccessPage />}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
