import AttacheLogo from '../images/attache.png';
import XeroLogo from '../images/xero-logo.png';
import ProntoLogo from '../images/pronto.png';
import MyobARLogo from '../images/myob_ar.png';
import AbmLogo from '../images/abm-logo.png';
import SageLogo from '../images/sage_logo.png';
import SapB1Logo from '../images/sap-b1-logo.png';
import BanjoLogo from '../images/banjo-logo.png';
import CsvLogo from '../images/csv_logo.png';
import ODBCLogo from '../images/odbc_logo.png';

export default {
  xeroapp: {
    label: 'XeroApp',
    providerName: 'XERO',
    logo: XeroLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate', stepSubmit: true },
      { id: 's2', title: 'Done', component: '' },
    ],
  },
  xero: {
    label: 'Xero',
    providerName: 'XERO',
    logo: XeroLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '' },
      { id: 's2', title: 'Xero Authorization', component: '' },
      { id: 's3', title: 'Done', component: '' },
    ],
  },
  attache: {
    label: 'Attaché',
    providerName: 'ATTACHE_BI',
    logo: AttacheLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  abm: {
    label: 'Advanced Business Manager',
    providerName: 'ABM',
    logo: AbmLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  sage: {
    label: 'Sage',
    providerName: 'SAGE',
    logo: SageLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  pronto: {
    label: 'Pronto',
    providerName: 'PRONTO',
    logo: ProntoLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate', stepSubmit: false },
      { id: 's2', title: 'Pronto Authorization', component: '', validate: 'validate', stepSubmit: true },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  arclassic: {
    label: 'MYOB AccountRight Classic',
    providerName: 'AR_CLASSIC',
    logo: MyobARLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  sapb1: {
    label: 'SAP B1',
    providerName: 'SAP_B1',
    logo: SapB1Logo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  banjo: {
    label: 'BANJO',
    providerName: 'BANJO',
    logo: BanjoLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  csv: {
    label: 'CSV',
    providerName: 'CSV',
    logo: CsvLogo,
    cloudIntegration: true,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
  custom: {
    label: 'CUSTOM',
    providerName: 'CUSTOM',
    logo: ODBCLogo,
    steps: [
      { id: 's1', title: 'Your Information', component: '', validate: 'validate' },
      { id: 's3', title: 'Configuration', component: '' },
    ],
  },
};
