import { observable, action, configure, runInAction } from 'mobx';
import Step1Model from '../model/Step1Model';
import Step2Model from '../model/Step2Model';
import TemplateStore from './TemplateStore';
import StalkerStore from './StalkerStore';
import ApiService from '../services/ApiService';

configure({ enforceActions: 'observed' });

const getQueryParams = qs => {
  const innerQS = qs.split('+').join(' ');
  const params = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;
  let tokens;

  while ((tokens = re.exec(innerQS))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

class HomeStore {
  @observable
  option = 'papi';

  @observable
  hasErrorOnStep1 = false;

  @observable
  step1Errors = [];

  @observable
  showPass = false;

  @observable
  currentStep = 0;

  @observable
  totalSteps = 2;

  @observable
  isLoading = false;

  @observable
  messageSuccess = '';

  @observable
  isCopied = false;

  @observable
  link = '';

  @observable
  key = '';

  @observable
  isCapsLockEnabled = false;

  constructor() {
    this.step1Model = new Step1Model();
    this.step2Model = new Step2Model();
    this.reCaptchaToken = '';
  }

  @action
  init(match, location) {
    this.step1Model.createSteps(match.params.provider);
    this.step1Model.initFromParams(getQueryParams(location.search));
  }

  @action
  goToBack() {
    this.hasErrorOnStep1 = false;
    this.step1Errors.clear();

    this.currentStep--;
  }

  @action
  goToNext() {
    this.hasErrorOnStep1 = false;
    this.step1Errors.clear();

    if (Object.keys(this.step1Model.steps[this.currentStep]).includes('validate')) {
      if (this.currentStep === 0) {
        const validate = this.step1Model.validate();
        this.hasErrorOnStep1 = validate.hasError;
        this.step1Errors = validate.messages;
      }

      if (this.currentStep === 1 && this.option === 'connect') {
        const validate = this.step2Model.validate();
        this.hasErrorOnStep1 = validate.hasError;
        this.step1Errors = validate.messages;
        this.updateValue('enableCloudIntegration', true);
      }

      if (!this.hasErrorOnStep1) {
        if (
          Object.keys(this.step1Model.steps[this.currentStep]).includes('stepSubmit') &&
          !this.step1Model.steps[this.currentStep].stepSubmit
        ) {
          this.currentStep++;
          return;
        }

        this.isLoading = true;

        StalkerStore.track('Registration - Form Submitted', {
          'Button Name': 'Submit',
          email: this.step1Model.contactEmail,
        });

        const params = this.step1Model.toParams();
        if(params.accountingSystem !== "CSV") {
          params.integrationAuthData = this.step2Model.toParams();
        }

        ApiService.save(params)
          .then(response => {
            runInAction('Saved', () => {
              TemplateStore.enqueueSnackbar({
                message: 'Success',
                options: {
                  variant: 'success',
                },
              });

              this.messageSuccess = response.data.message;
              if (response.data.registration.details) {
                this.link = response.data.registration.details.find(d => d.name === 'installerLink').value;
                this.key = response.data.registration.details.find(d => d.name === 'apiKey').value;
              }
              this.isLoading = false;
              this.currentStep++;

              if (this.step1Model.contactEmail) {
                StalkerStore.emailIdentify(this.step1Model.contactEmail);
                StalkerStore.track('Registration - Complete', {
                  email: this.step1Model.contactEmail,
                });
              }
            });
          })
          .catch(httpError => {
            if (httpError.response) {
              runInAction('Set Error', () => {
                this.isLoading = false;
                TemplateStore.enqueueSnackbar({
                  message: 'Ops, there are something wrong.',
                  options: {
                    variant: 'error',
                  },
                });
                this.hasErrorOnStep1 = true;
                if (Object.keys(httpError.response.data).includes('errors')) {
                  httpError.response.data.errors.forEach(err => {
                    const field = err.field.replace('registration.', '');
                    if (field === 'accountingSystem') {
                      TemplateStore.enqueueSnackbar({
                        message: err.description,
                        options: {
                          variant: 'error',
                        },
                      });
                    }
                    this.step1Errors.push({ field: field, message: err.description });
                  });
                } else {
                  console.warn(httpError.response.data.message);
                }
              });
            } else {
              console.error(httpError);
              runInAction('Set Error', () => {
                this.isLoading = false;
                TemplateStore.enqueueSnackbar({
                  message: 'Ops, there are something wrong.',
                  options: {
                    variant: 'error',
                  },
                });
              });
            }
          });
      }
    } else {
      this.currentStep++;
    }
  }

  @action
  reCaptchaRegistration(token) {
    this.reCaptchaToken = token;
  }

  hasErrorInField(field) {
    const error = this.step1Errors.find(error => error.field === field);
    return { hasError: error !== undefined, message: error ? error.message : '' };
  }

  @action
  setTotalSteps() {
    this.totalSteps = this.step1Model.steps.length;
  }

  @action
  updateValue(attr, value) {
    this.step1Model.updateAttr(attr, value);
  }

  @action
  updateValueStep2(attr, value) {
    this.step2Model.updateAttr(attr, value);
  }

  @action
  togglePass() {
    this.showPass = !this.showPass;
  }

  @action
  markCopied() {
    this.isCopied = true;
    TemplateStore.enqueueSnackbar({
      message: 'Copied',
      options: {
        variant: 'success',
      },
    });
  }

  @action
  checkCapsLock(e) {
    this.isCapsLockEnabled = e.hasOwnProperty('getModifierState') ? e.getModifierState('CapsLock') : false;
  }

  @action
  reset() {
    this.hasErrorOnStep1 = false;
    this.step1Errors = [];
    this.showPass = false;
    this.currentStep = 0;
    this.totalSteps = 2;
    this.isLoading = false;
    this.messageSuccess = '';
    this.isCopied = false;
    this.link = '';
    this.key = '';
    this.isCapsLockEnabled = false;
  }
}

export default new HomeStore();
