import RestImage from '../../../images/rest.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import configs from '../../../configs';
import React from 'react';
import { observer } from 'mobx-react';

const SuccessPage = observer(function() {
  return (
    <div
      style={{
        padding: '215px 24px 24px 24px',
        textAlign: 'center',
        background: `url(${RestImage}) center top no-repeat`,
      }}
    >
      <Grid item xs={12} style={{ padding: '20px', color: '#335266' }}>
        <Typography variant="body2" gutterBottom>
          Once you are ready to start, follow the steps on our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.ezycollect.com.au/en/articles/3461343-attache-bi-ezycollect-installer-set-up"
            style={{ color: '#4CC3B6' }}
          >
            setup guide
          </a>
          , it only takes a couple of minutes.
        </Typography>

        <Typography variant="body2" gutterBottom>
          To login to your instance, go to the{' '}
          <a target="_blank" rel="noopener noreferrer" href={configs.login_link} style={{ color: '#4CC3B6' }}>
            {' '}
            ezyCollect login page
          </a>
          .
        </Typography>
      </Grid>
    </div>
  );
});

export default SuccessPage;
