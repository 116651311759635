import React, { Component } from 'react';
import { observer } from 'mobx-react';
import HomeStore from '../../../store/HomeStore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import Recaptcha from 'react-google-invisible-recaptcha';
import Typography from '@material-ui/core/Typography';
import configs from '../../../configs';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';

@observer
class StepOne extends Component {
  constructor(props) {
    super(props);
    this.handlerSubmit = this.handlerSubmit.bind(this);

    this.fields = {
      companyName: {
        label: 'Company Name',
        name: 'companyName',
        validate: null,
        required: true,
      },
      contactName: {
        label: 'Your Name',
        name: 'contactName',
        validate: null,
        required: true,
      },
      contactEmail: {
        label: 'E-mail',
        name: 'contactEmail',
        validate: null,
        required: true,
      },
      password: {
        label: 'Password',
        name: 'password',
        validate: null,
        required: true,
      },
      contactPhone: {
        label: 'Phone',
        name: 'contactPhone',
        validate: null,
        required: false,
      },
      integrationHost: {
        label: 'Host',
        name: 'integrationHost',
        validate: null,
        required: false,
      },
      integrationUser: {
        label: 'User',
        name: 'integrationUser',
        validate: null,
        required: false,
      },
      integrationPassword: {
        label: 'Password',
        name: 'integrationPassword',
        validate: null,
        required: false,
      },
    };

    document.onkeyup = e => {
      HomeStore.checkCapsLock(e);
    };

    document.onkeydown = e => {
      HomeStore.checkCapsLock(e);
    };
  }

  handlerSubmit(e) {
    this.recaptcha.execute();
    e.preventDefault();
  }

  render() {
    this.fields.companyName.validate = HomeStore.hasErrorInField(this.fields.companyName.name);
    this.fields.contactName.validate = HomeStore.hasErrorInField(this.fields.contactName.name);
    this.fields.contactEmail.validate = HomeStore.hasErrorInField(this.fields.contactEmail.name);
    this.fields.password.validate = HomeStore.hasErrorInField(this.fields.password.name);

    if (HomeStore.step1Model.cloudIntegration) {
      this.fields.integrationHost.validate = HomeStore.hasErrorInField(this.fields.integrationHost.name);
      this.fields.integrationUser.validate = HomeStore.hasErrorInField(this.fields.integrationUser.name);
      this.fields.integrationPassword.validate = HomeStore.hasErrorInField(this.fields.integrationPassword.name);
    }

    return (
      <form onSubmit={this.handlerSubmit} autoComplete="off">
        <input id="_suburb" type="text" style={{ display: 'none' }} disabled />
        <Recaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey={configs.captcha_key}
          onResolved={() => HomeStore.goToNext()}
        />
        <Box component="div" m={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label={this.fields.companyName.label}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name={this.fields.companyName.name}
                id="companyName"
                value={HomeStore.step1Model.companyName}
                error={this.fields.companyName.validate.hasError}
                helperText={this.fields.companyName.validate.message}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={this.fields.contactName.label}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name={this.fields.contactName.name}
                id="contactName"
                value={HomeStore.step1Model.contactName}
                error={this.fields.contactName.validate.hasError}
                helperText={this.fields.contactName.validate.message}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name="contactPhone"
                id="contactPhone"
                value={HomeStore.step1Model.contactPhone}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Partner"
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                name="referral"
                id="referral"
                disabled
                value={HomeStore.step1Model.referral}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StarTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {HomeStore.step1Model.cloudIntegration && (
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={HomeStore.step1Model.enableCloudIntegration}
                      onChange={e => HomeStore.updateValue('enableCloudIntegration', e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Cloud Integration?"
                />
              </Grid>
            )}
            {HomeStore.step1Model.cloudIntegration && HomeStore.step1Model.enableCloudIntegration && (
              <>
                <Grid item xs={12}>
                  <FormControl disabled className="ez-fw">
                    <InputLabel id="connector-type">Connector Type</InputLabel>
                    <Select id="demo-simple-select" value={HomeStore.step1Model.integrationConnectorType} disabled>
                      <MenuItem value={'SFTP'}>SFTP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Host"
                    margin="dense"
                    variant="outlined"
                    autoComplete="new-string"
                    name="integrationHost"
                    id="integrationHost"
                    className="ez-fw"
                    value={HomeStore.step1Model.integrationHost}
                    onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                    error={this.fields.integrationHost.validate.hasError}
                    helperText={this.fields.integrationHost.validate.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SettingsSystemDaydreamIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Username"
                    margin="dense"
                    variant="outlined"
                    autoComplete="new-string"
                    name="integrationUser"
                    id="integrationUser"
                    className="ez-fw"
                    value={HomeStore.step1Model.integrationUser}
                    onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                    error={this.fields.integrationUser.validate.hasError}
                    helperText={this.fields.integrationUser.validate.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineTwoToneIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Password"
                    margin="dense"
                    variant="outlined"
                    autoComplete="new-string"
                    name="integrationPassword"
                    id="integrationPassword"
                    className="ez-fw"
                    value={HomeStore.step1Model.integrationPassword}
                    onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                    error={this.fields.integrationPassword.validate.hasError}
                    helperText={this.fields.integrationPassword.validate.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockTwoToneIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Divider className="ez-vm-24" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label={this.fields.contactEmail.label}
                name={this.fields.contactEmail.name}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                id="contactEmail"
                type="email"
                error={this.fields.contactEmail.validate.hasError}
                helperText={this.fields.contactEmail.validate.message}
                value={HomeStore.step1Model.contactEmail}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={`${this.fields.password.label} ${HomeStore.isCapsLockEnabled ? ' [Caps Lock Enabled]' : ''}`}
                name={this.fields.password.name}
                margin="dense"
                variant="outlined"
                autoComplete="new-string"
                id="password"
                error={this.fields.password.validate.hasError}
                helperText={this.fields.password.validate.message}
                value={HomeStore.step1Model.password}
                className="ez-fw"
                onChange={e => HomeStore.updateValue(e.target.name, e.currentTarget.value)}
                type={HomeStore.showPass ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockTwoToneIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => HomeStore.togglePass()}
                      >
                        {HomeStore.showPass ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ padding: '0 15px' }}>
                <Typography variant="caption" display="inline" gutterBottom color="secondary">
                  Between 6 to 22 characters, with at least 1 lowercase, 1 uppercase and 1 number
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} className="ez-text-right">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                onClick={() => this.recaptcha.execute()}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

export default StepOne;
