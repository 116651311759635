import { observable } from 'mobx';
import ErrorMessages from '../constants/ErrorMessages';
import Steps from '../constants/Steps';

class Step1Model {
  @observable
  companyName = '';

  @observable
  contactName = '';

  @observable
  contactEmail = '';

  @observable
  password = '';

  @observable
  contactPhone = '';

  @observable
  referral = '';

  @observable
  steps = [];

  @observable
  logo = null;

  @observable
  label = '';

  @observable
  accountingSystem = '';

  @observable
  termsAndConditions = false;

  @observable
  cloudIntegration = false;

  @observable
  enableCloudIntegration = false;

  @observable
  integrationConnectorType = 'SFTP';

  @observable
  integrationHost = '';

  @observable
  integrationUser = '';

  @observable
  integrationPassword = '';

  constructor(companyName = '', contactName = '', contactEmail = '', password = '', contactPhone = '', referral = '') {
    this.companyName = companyName;
    this.contactName = contactName;
    this.contactEmail = contactEmail;
    this.password = password;
    this.contactPhone = contactPhone;
    this.referral = referral;

    this.passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})');
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  createSteps(provider) {
    if (provider && Object.keys(Steps).includes(provider)) {
      this.steps.replace(Steps[provider].steps);
      this.logo = Steps[provider].logo;
      this.label = Steps[provider].label;
      this.accountingSystem = Steps[provider].providerName;

      if (Steps[provider]?.cloudIntegration) {
        this.cloudIntegration = true;
      }
    } else {
      this.steps.replace(Steps.attache.steps);
      this.logo = Steps.attache.logo;
      this.label = Steps.attache.label;
      this.accountingSystem = Steps.attache.providerName;
    }
    document.title = `Ezy Registration - ${this.label}`;
  }

  initFromParams(queryParams) {
    this.companyName = queryParams.companyName || '';
    this.contactName = queryParams.contactName || '';
    this.contactEmail = queryParams.contactEmail || '';
    this.contactPhone = queryParams.contactPhone || '';
    this.referral = queryParams.referral || '';

    if (this.cloudIntegration) {
      this.enableCloudIntegration = Boolean(queryParams.cloudIntegration) || false;
    }
  }

  updateAttr(attr, value) {
    if (attr === 'password') {
      if (value.length < 22) {
        this[attr] = value;
      }
    } else {
      this[attr] = value;
    }
  }

  validate() {
    const requiredFields = ['companyName', 'contactName', 'contactEmail', 'password'];

    if (this.cloudIntegration && this.enableCloudIntegration) {
      requiredFields.push('integrationHost');
      requiredFields.push('integrationUser');
      requiredFields.push('integrationPassword');
    }

    const error = {
      hasError: false,
      messages: [],
    };

    requiredFields.forEach(field => {
      if (this[field].trim() === '') {
        error.hasError = true;
        error.messages.push({ field: field, message: ErrorMessages.form.required });
      }
    });

    if (!error.hasError) {
      if (!this.passRegex.test(this.password.trim())) {
        error.hasError = true;
        error.messages.push({ field: 'password', message: ErrorMessages.form.password });
      }

      if (!this.contactEmail.trim().match(this.emailRegex)) {
        error.hasError = true;
        error.messages.push({ field: 'contactEmail', message: ErrorMessages.form.email });
      }
    }

    return error;
  }

  toParams() {
    const integrationData = this.enableCloudIntegration
      ? {
          isCloudIntegration: true,
          integrationConnectorType: this.integrationConnectorType,
          integrationAuthData: {
            host: this.integrationHost,
            username: this.integrationUser,
            pass: this.integrationPassword,
          },
        }
      : { isCloudIntegration: false };

    return {
      accountingSystem: this.accountingSystem,
      companyName: this.companyName,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      referral: this.referral,
      password: this.password,
      ...integrationData,
    };
  }
}

export default Step1Model;
