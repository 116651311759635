import { observable } from 'mobx';
import ErrorMessages from '../constants/ErrorMessages';

class Step2Model {
  @observable
  host = '';

  @observable
  username = '';

  @observable
  pass = '';

  @observable
  company = '';

  constructor(host = '', username = '', pass = '', company = '') {
    this.host = host;
    this.username = username;
    this.pass = pass;
    this.company = company;

    this.hostRegex = new RegExp(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/);
  }

  validate() {
    const requiredFields = ['host', 'username', 'pass', 'company'];

    const error = {
      hasError: false,
      messages: [],
    };

    requiredFields.forEach(field => {
      if (this[field].trim() === '') {
        error.hasError = true;
        error.messages.push({ field: field, message: ErrorMessages.form.required });
      }
    });

    if (!this.host.trim().match(this.hostRegex)) {
      error.hasError = true;
      error.messages.push({ field: 'host', message: ErrorMessages.form.host });
    }

    return error;
  }

  updateAttr(attr, value) {
    this[attr] = value;
  }

  toParams() {
    return {
      host: this.host,
      username: this.username,
      pass: this.pass,
      company: this.company,
    };
  }
}

export default Step2Model;
